.home__machines{
    display:grid;
    gap: 2rem;
    grid-template-columns: repeat(auto-fill, minmax(300px, 1fr));
    // when it gets bigger than min-width
    @media (min-width: $layout-breakpoint-small) {
        grid-template-columns: repeat(auto-fill, minmax(400px, 1fr));
    }
}

.home__card{
    position:relative;
    display: flex;
    justify-content: space-around;
    align-items: center;
    color: rgb(0, 0, 0);
    height: 400px;
    width: auto;
    margin-top: 16px;
    img {
        height: 100%;
        width: 100%;
        object-fit:cover;
        z-index: 0;
    }
    .info__overlay{
        position: absolute;
        top: 80%;
        background-color: #000;
        opacity: 0.9;
        width: 100%;
        height: 20%;
        width: 100%;
        z-index: 1;
        h1{
            color: white;
            opacity: 1;
            font-size: 2.5rem;
            text-align: center;
            padding: 2%;
        }        
    }
    &:hover{
        box-shadow: rgba(5, 8, 20, 0.5) .15rem 0.15rem 0.5rem;
        transform: translateY(-1px);
    }
    
}

