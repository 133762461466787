// SCSS for three.js model
.printer__canvas{
    height: 92%;
    width: 100%;
    border: 5px;
}


.livefeed{
  width: 100%;
  height: 100%;
}
  
.livefeedvid{
  width: 70%;
  height: 100%;
  transform: rotate(90deg);
  margin-top: -35px;
  margin-left: 15%;
  z-index: -10;
  @media (min-width: $layout-breakpoint-small) {
    // width: 70%;
    // height: 100%;
    margin-top: -25px;
    margin-left: 16%;
  }
}

// SCSS for TERMINAL
  .terminal{
    height: 100%;
  }

  div.react-terminal{
    height: 100%;
    inline-size: 100%;
    word-break: break-all; 
  }
  
  div.react-terminal-wrapper {
    height: 100%;
    background: #252a33;
    color: #eee;
    font-size: 16px;
    font-family: 'Fira Mono', Consolas, Menlo, Monaco, 'Courier New', Courier, monospace;
    padding: 30px 15px 15px;
    position: relative;
    
  }
  
  div.react-terminal-wrapper::before{
    content: none;
  }

  .terminal-hidden-input{
    top:-1000px;
  }


// SCSS for Controller
  .controller{
    display: flex;
    flex-direction: row;
    align-items: center;
    align-items: center;
    justify-content: center;
    button{
      border-radius: 0px;
    }
    button:hover{
      cursor: pointer;
      transform: scale(1.01);
    }
    .controller_btn{
      width: 100%;
      height: 100%;
      background-color: red;
      color: white;
      display: flex;
      align-items: center;
      justify-content: center;
      // transform: scale(1.2);
    }
    .controller_btn:hover{
      cursor: pointer;
      transform: scale(1.05);
    }
    .controller_btn:active {
      background-color: #137c50eb;
    }
  }
  .control__movement{
    display: grid;
    gap: 5px 7px;
    width: 140px;
    grid-template-columns: repeat(3, 40px);
    grid-template-rows: repeat(3, 40px);
    z-index: 10;
  }
  .control__zed {
    display: grid;
    row-gap: 5px;
    width: 65px;
    grid-template-rows: repeat(3, 40px);
    margin-left: 55px;
  }
  .left {
    grid-column-start: 1;
    grid-row-start: 2;
  }
  .right {
    grid-column-start: 3;
    grid-row-start: 2;
  }
  .up {
    grid-column-start: 2;
    grid-row-start: 1;
  }
  .center {
    grid-column-start: 2;
    grid-row-start: 2;
  }
  .down {
    grid-column-start: 2;
    grid-row-start: 3;
  }
  .home__btn{
    grid-column-start: 1;
    grid-row-start: 3;
  }
  .product__btn{
    grid-column-start: 3;
    grid-row-start: 3;
  }
  .levels{
    display: flex;
    flex-direction: column;
    padding-inline: 2rem;
    justify-content: center;
    gap: 1px;
    transform: scale(0.7);
  }

  .position{
    margin: 1.5rem;
    color: white;
    .position__table{
      display: flex;
      flex-direction: row;
      justify-content: space-between;
      align-content: center;
      margin-bottom: 1rem;
    }
}