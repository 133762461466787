// Style that all Dashboard page should have
// dashboard div wrapping aounrd the whole page (without header)
// dashboard__maincard div wrapping around all the components
// three different componenets: dashboard__comp, dashboard__blankcomp, dashboard__longcomp div

// comp__header div for each component header


.dashboard{
  background-color: $black;
  display: grid;
  grid-template-columns: 300px, auto;
  color: rgb(0, 0, 0);
  button{
    color: white;
    background-color: red;
  }
}

.dashboard__maincard {
  height: 100%;
  margin: 1rem;
  padding-bottom: 1rem;
  display: grid;
  column-gap: 1rem;
  grid-template-columns: repeat(auto-fill, minmax(400px, 1fr));
  .dashboard__comp{
    background-color: $darkgrey;
    display: flex;
    flex-direction: column;
    height: 400px;
    border: 2px solid #000000;
  }
  .dashboard__blankcomp{
    display: flex;
    flex-direction: column;
    height: 400px;
    // border: 2px solid #000000;
    justify-content: center;
  }
  .dashboard__longcomp{
    background-color: $darkgrey;
    display: flex;
    flex-direction: column;
    border: 2px solid #000000;
    margin-bottom: 1rem;
    @media (min-width: $layout-breakpoint-small) {
      grid-column: span 2;
    }
  }
}

.comp__header{
    margin-inline: 1%;
    margin-top: 2%;
    margin-bottom: 1%;
    display: flex;
    justify-content: space-between;
    align-items: center;
    .more__icon{
      transform: scale(1.5);
    }
}
