body {
    background: $background;
}

.icon{
    cursor: pointer;
}

button {
    appearance: none;
    background-color: $darkgrey;
    border: 2px solid $darkgrey;
    border-radius: 15px;
    box-sizing: border-box;
    color: #FFFFFF;
    cursor: pointer;
    display: inline-block;
    font-family: Roobert,-apple-system,BlinkMacSystemFont,"Segoe UI",Helvetica,Arial,sans-serif,"Apple Color Emoji","Segoe UI Emoji","Segoe UI Symbol";
    font-size: 16px;
    font-weight: 600;
    line-height: normal;
    margin-top: 1%;
    margin-bottom: 1%;
    min-height: 60px;
    min-width: 0;
    outline: none;
    padding: 16px 24px;
    text-align: center;
    text-decoration: none;
    transition: all 300ms cubic-bezier(.23, 1, 0.32, 1);
    user-select: none;
    -webkit-user-select: none;
    touch-action: manipulation;
    width: 100%;
    will-change: transform;
    &:disabled {
      pointer-events: none;
    }
    &:hover{
      box-shadow: rgba(0, 0, 0, 0.25) 0 8px 15px;
      transform: translateY(-2px);
    }
    &:active{
      box-shadow: none;
      transform: translateY(0);
    }
  }
  
  input:not([type="submit"]):not([type="reset"]):not([type="button"])
  {
    font-size:  16px; 
    padding:  20px 0px; 
    margin-top: 1%;
    margin-bottom: 1%;
    height:  56px; 
    border:  none; 
    border-bottom:  solid 1px rgba(0,0,0,.1); 
    background:  #fff; 
    width:  100%;
    box-sizing:  border-box; 
    transition:  all .3s linear; 
    color:  #000; 
    font-weight:  400;
    -webkit-appearance:  none; 
    &:focus {
      outline: 0; 
    }
  }


  .SpinnerLoader{
    position: absolute;
    top: 0;
    left: 0;
    height: 100%;
    width: 100%;
    backdrop-filter: blur( 7px );
    background: rgba(30, 30, 30, 0.35);
    display: flex;
    height: 100%; 
    justify-content: center;
    color: white;
    z-index: 100;
    circle{
        fill: white;
    }
}