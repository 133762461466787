.profile{
    width: 100%;
    height: 100%;
    padding: 2rem;
    display: flex;
    flex-direction: column;
    justify-content: center;
    text-align: center;
     // when it gets bigger than min-width
    @media (min-width: $layout-breakpoint-small) {
        width: 50%;
        margin-left: 25%;
    }
}