// Generic Page with the Page Title
.generic__page{
    margin-inline: 2%;
    margin-bottom: 2rem;
    .header__div{
        width: 100%;
        height: 10px;
        border-bottom: 1px solid black;
        text-align: center;
        margin-bottom: 20px;
        margin-top: 10px;
        @media (min-width: $layout-breakpoint-small) {
        height: 20px;
        margin-bottom: 40px;
            margin-top: 15px;
        }
    }
    .available__header{
        font-size: 20px;
        background-color: #f0eff1;
        padding: 10px 10px;
        @media (min-width: $layout-breakpoint-small) {
            font-size: 40px;
        }
    }
}