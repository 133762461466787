.header{
    height: 72px;
    width: 100%;
    position: sticky;
    // top: 0;
    // z-index: 100;
    display: flex;
    align-items: center;
    justify-content: space-between;
    background-color: $darkcyan;
    background-repeat: no-repeat;
}

.header__title{
    display: flex;
    align-items: center;
    font-family: 'Lato', sans-serif;
    color: white;
    text-decoration: none;

}

.header__logo{
    height: 100%;
    margin: none;
    object-fit: contain;
}

.header__nav{
    display: flex;
    .header__icon {
        margin-inline: 13px;
        transform: scale(2.4);
        color: white;
        :hover{
            cursor: pointer;
        }
        :last-of-type{
            margin-right: 2px;
        }
    }
    .header__status{
        transform: scale(1.5);
        margin-top: 4px;
        margin-right: 5px;
    }
}



