// .console__canvas {
//     height: 100%;
//     width: 100%;
// }

// .console{
//     height: 100%;
//     width: 100%;
// }

.landing {
    display: flex;
    justify-content: flex-end;
    background-image: linear-gradient(to bottom, #2c1221, #251222, #1d1322, #171220, #11111d, #0e121c, #0b121b, #091219, #08141a, #08171a, #09181a, #0c1a19);  /* fallback for old browsers */
    /* background: -webkit-linear-gradient(to right, #182848, #4b6cb7);  Chrome 10-25, Safari 5.1-6 */
    /* background: linear-gradient(to right, #182848, #4b6cb7); W3C, IE 10+/ Edge, Firefox 16+, Chrome 26+, Opera 12+, Safari 7+ */
    width: 100%;
    height: 100vh;
    color: white;
}

.console__obj{
    position: relative; 
    width: 50vw; 
    height: 70vh;
    margin:2rem;
    margin-top: -5rem;
    visibility: hidden;
    @media (min-width: $layout-breakpoint-small) {
        visibility: visible;
    }
}

.login{
    overflow: hidden;
    margin-top: 5rem;
    h1{
        text-align: center;
        margin-bottom: 1rem;
    }
    .login__input{
        @media (min-width: $layout-breakpoint-small) {
            width: 80%;
            margin-left: 10%;
        }
        // width: 80%;
        // margin-left: 10%;
    }
    .login__form{
        padding: 2rem 2rem 0rem 2rem;
        // height: 100vh;
        width: 100%;
        @media (min-width: $layout-breakpoint-small) {
            width: 50%;
            height: 100%;
            margin-left: 5rem;
        }
    }
    @media (min-width: $layout-breakpoint-small) {
        display: flex;
        margin-top: 9rem;

    }

}